<template>
  <v-container
    v-if="$isEnabledProperty($LISTADO_CTA_CTE)"
    id="crud"
    fluid
    tag="section"
  >
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>Consulta de {{ title }}</v-toolbar-title>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="processedData"
        :search="search"
        sort-by="created_at"
        sort-desc
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="pr-2 mt-6">
            <v-row>
              <v-col xs="12" sm="10" md="8" class="pt-20">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="'Búsqueda por Ente'"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col xs="12" sm="2" md="4" class="pt-28 text-right">
                <v-btn color="secondary" class="btn__rounded">
                  <download-excel
                    :fields="json_fields"
                    :fetch="fetchData"
                    :type="dataExportType"
                    :name="
                      title +
                      '-' +
                      $formatDate(nowDate) +
                      '-' +
                      $getDate() +
                      '.' +
                      dataExportType
                    "
                  >
                    <v-icon left class="white--text"
                      >mdi-file-excel-box-outline</v-icon
                    >
                    Exportar Excel
                  </download-excel>
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            :color="$cv('btnActivo')"
            title="Ver Comprobante"
            @click="alert('Código en proceso', 'Funcionalidad sin terminar')"
          >
            mdi-file-document
          </v-icon>
        </template>
        <template v-slot:item.debe="{ item }">
          {{ $formatCurrency(item.debe) }}
        </template>
        <template v-slot:item.haber="{ item }">
          {{ $formatCurrency(item.haber) }}
        </template>
        <template v-slot:item.saldo="{ item }">
          {{ $formatCurrency(item.saldo) }}
        </template>
      </v-data-table>
    </v-card>
    <v-btn
      :color="$cv('principal')"
      class="btn__rounded btn__margin"
      @click="$goBack()"
    >
      <v-icon color="white" class="px-1">mdi-arrow-left-thin</v-icon>
      Atrás
    </v-btn>
  </v-container>
</template>

<script>
function title() {
  return "Cuenta Corriente";
}

export default {
  name: "CuentaCorrienteConsulta",

  data: (vm) => ({
    title: title(),
    search: "",
    nowDate: new Date().toISOString().slice(0, 10),
    headers: [
      { text: "", value: "actions", filterable: false, sortable: false },
      { text: "Fecha", value: "fecha", filterable: true },
      { text: "Ente", value: "ente", filterable: true },
      { text: "Cliente", value: "cliente", filterable: true },
      { text: "Tipo Comprobante", value: "comprobante_tipo", filterable: true },
      {
        text: "Nro de Comprobante",
        value: "comprobante_numero",
        filterable: true,
      },
      { text: "Debe", value: "debe", sortable: false },
      { text: "Haber", value: "haber", sortable: false },
      { text: "Saldo", value: "saldo", sortable: false },
    ],
    json_fields: {
      Fecha: "fecha",
      Ente: "ente",
      Cliente: "cliente",
      "Tipo Comprobante": "comprobante_tipo",
      "Número de Comp.": "comprobante_numero",
      Debe: "debe",
      Haber: "haber",
      Saldo: "saldo",
    },
    dataExportType: "xls",
    filteredData: [
      {
        id: 1,
        fecha: "01/05/2024",
        ente: this.$db("MJ"),
        cliente: "GRAND AUTOS SRL",
        comprobante_tipo: "Factura",
        comprobante_numero: "44158-98747823",
        total: 126743,
      },
      {
        id: 2,
        fecha: "01/05/2024",
        ente: this.$db("MJ"),
        cliente: "GRAND AUTOS SRL",
        comprobante_tipo: "Recibo",
        comprobante_numero: "4688",
        total: 126743,
      },
      {
        id: 3,
        fecha: "02/05/2024",
        ente: this.$db("ACARA"),
        cliente: "GRAND AUTOS SRL",
        comprobante_tipo: "Factura",
        comprobante_numero: "44158-12345688",
        total: 80569,
      },
      {
        id: 4,
        fecha: "02/05/2024",
        ente: this.$db("ACARA"),
        cliente: "GRAND AUTOS SRL",
        comprobante_tipo: "Recibo",
        comprobante_numero: "4588",
        total: 80569,
      },
      {
        id: 5,
        fecha: "03/05/2024",
        ente: this.$db("MJ"),
        cliente: "GRAND AUTOS SRL",
        comprobante_tipo: "Factura",
        comprobante_numero: "44158-12345678",
        total: 123981,
      },
      {
        id: 6,
        fecha: "03/05/2024",
        ente: this.$db("MJ"),
        cliente: "GRAND AUTOS SRL",
        comprobante_tipo: "Recibo",
        comprobante_numero: "4417",
        total: 123981,
      },
      {
        id: 7,
        fecha: "04/05/2024",
        ente: this.$db("ACARA"),
        cliente: "GRAND AUTOS SRL",
        comprobante_tipo: "Factura",
        comprobante_numero: "44158-12385678",
        total: 113672,
      },
      {
        id: 8,
        fecha: "02/05/2024",
        ente: this.$db("ACARA"),
        cliente: "GRAND AUTOS SRL",
        comprobante_tipo: "Recibo",
        comprobante_numero: "4415",
        total: 113672,
      },
      {
        id: 9,
        fecha: "05/05/2024",
        ente: this.$db("MJ"),
        cliente: "GRAND AUTOS SRL",
        comprobante_tipo: "Factura",
        comprobante_numero: "44158-87654321",
        total: 95042,
      },
      {
        id: 10,
        fecha: "05/05/2024",
        ente: this.$db("MJ"),
        cliente: "GRAND AUTOS SRL",
        comprobante_tipo: "Recibo",
        comprobante_numero: "4419",
        total: 95042,
      },
      {
        id: 11,
        fecha: "06/05/2024",
        ente: this.$db("ACARA"),
        cliente: "GRAND AUTOS SRL",
        comprobante_tipo: "Factura",
        comprobante_numero: "44158-52345678",
        total: 127490,
      },
      {
        id: 12,
        fecha: "06/05/2024",
        ente: this.$db("ACARA"),
        cliente: "GRAND AUTOS SRL",
        comprobante_tipo: "Recibo",
        comprobante_numero: "4412",
        total: 127490,
      },
      {
        id: 13,
        fecha: "07/05/2024",
        ente: this.$db("MJ"),
        cliente: "GRAND AUTOS SRL",
        comprobante_tipo: "Factura",
        comprobante_numero: "44158-23456789",
        total: 135820,
      },
      {
        id: 14,
        fecha: "07/05/2024",
        ente: this.$db("MJ"),
        cliente: "GRAND AUTOS SRL",
        comprobante_tipo: "Recibo",
        comprobante_numero: "4411",
        total: 135820,
      },
    ],
  }),

  computed: {
    processedData() {
      let saldoPorEnte = {};
      const searchLower = this.search.toLowerCase();
      return this.filteredData
        .filter((item) => item.ente.toLowerCase().includes(searchLower))
        .map((item) => {
          const debe = item.comprobante_tipo === "Factura" ? item.total : 0;
          const haber = item.comprobante_tipo === "Recibo" ? item.total : 0;

          saldoPorEnte[item.ente] = saldoPorEnte[item.ente] || 0;
          saldoPorEnte[item.ente] += debe - haber;

          return {
            ...item,
            debe: parseFloat(debe.toFixed(2)),
            haber: parseFloat(haber.toFixed(2)),
            saldo: parseFloat(saldoPorEnte[item.ente].toFixed(2)),
          };
        });
    },
  },

  mounted() {},

  methods: {
    async fetchData() {
      const response = this.processedData;
      return response;
    },

    alert(title, msg) {
      Swal.alert(title, msg);
    },
  },
};
</script>
